@font-face {
  font-family: "Courier Prime Sans";
  src: url("../fonts/CourierPrimeSans.ttf");
}

@import url("https://fonts.googleapis.com/css?family=Bitter");
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,700&display=swap");

$fonts: "Josefin Sans", "Bitter", "Courier Prime Sans", sans-serif;

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $fonts;
}
button {
  font-family: $fonts;
}
